.wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--blocker);
}

.notificationBox {
    background-color: var(--white);
    min-height: 45vh;
    max-height: 80vh;
    overflow-y: auto;
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.bold {
    font-weight: bold;
}

.buttonBar {
    padding: 1em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.topBar {
    width: 100%;
    margin: 0;
    text-align: center;
    font-size: 300%;
    padding: 0.5em 0;
}

.error {
    background-color: var(--red);
    color: var(--white);
}

.warning {
    background-color: var(--orange);
    color: var(--black);
}

.info {
    background-color: var(--green);
    color: var(--white);
}

.message {
    font-size: 1.5em;
    margin: 0;
}

.dependentDisplay,
.dependentDisplay ul {
    list-style: none;
}

.input {
    width: 80%;
}

.list {
    padding: 1em;
    margin: 0;
    max-height: 50%;
    overflow-y: auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.confirmationTextBox {
    width: 70%;
}

.confirmationTextBox * {
    width: 100%;
}

:root {
    --white: #ffffff;
    --lightGray: #dddddd;
    --darkGray: #666666;
    --black: #545454;
    --green: #3f8f7b;
    --lime: #a0d241;
    --purple: #8a458b;
    --red: #ff6840;
    --lightRed: #f68a6e;
    --orange: #fbba00;
    --lightOrange: #f6c64f;
    --lightBlue: #c6dbe1;
    --blue: #7ac0d6;

    --blocker: rgba(0, 0, 0, 0.5);
    --transparent: rgba(0, 0, 0, 0);

    --gradient: linear-gradient(90deg, var(--lime) 0%, var(--green) 100%);

    --local-gradient: linear-gradient(
        90deg,
        var(--lime) 0%,
        var(--purple) 100%
    );
    --dev-gradient: linear-gradient(90deg, var(--lime) 0%, var(--red) 100%);
    --test-gradient: linear-gradient(90deg, var(--lime) 0%, var(--orange) 100%);

    --shadow: 0 3px 8px -1px rgba(0, 0, 0, 0.1),
        0 10px 24px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);

    --inputBorder: 2px solid var(--lightGray);
}

@media (prefers-color-scheme: dark) {
    /*Todo Dark mode?*/
}

body {
    background-color: var(--white);
    color: var(--black);
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

a {
    color: var(--black);
    margin: 0;
    text-decoration: none;
}

#root {
    /*height: 100vh;*/
    display: flex;
    flex-direction: column;
}

#notification {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2000;
}

#login {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1900;
}

#modal {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1800;
}

#confirm {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1700;
}

#expanded {
    position: sticky;
    left: 0;
    bottom: 0;
    max-height: 30vh;
    overflow-y: auto;
}

#result_editor {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1700;
}

.container {
    display: flex;
    flex-direction: row;
}

h2 {
    text-transform: capitalize;
}

.list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.list li {
    font-size: 15pt;
    padding: 1em;
    cursor: pointer;
}

.profilePic {
    border-radius: 50%;
    width: 9em;
    padding: 1em;
}

.profileName {
    text-align: center;
}

.profilePicPlaceholder {
    width: 10em;
}

.priceViewerPlaceHolder {
    width: 30vw;
}

.bulkTable {
    display: grid;
    grid-template-columns: repeat(4, auto);
    border-right: 1px solid var(--lightGray);
    border-top: 1px solid var(--lightGray);
}

.competitors {
    margin-top: 3em;
}

.bulkTableHeader {
    font-weight: bold;
}

.bulkTable p {
    border-left: 1px solid var(--lightGray);
    border-bottom: 1px solid var(--lightGray);
    margin: 0;
    padding: 0.25em;
}

.metricTable {
    display: grid;
    grid-template-columns: repeat(10, auto);
}

.modalWrapper {
    width: 100vw;
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
}

.modal {
    border-top: 0.25em solid var(--green);
    pointer-events: all;
    overflow-y: auto;
    background-color: var(--white);
    width: 100vw;
}

.modal > * {
    margin: 1em;
}

.topBar {
    display: flex;
    justify-content: space-between;
}

.buttonBar {
    gap: 1em;
    display: flex;
    align-items: center;
}

.buttonBar {
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
}

.buttonBar button {
    text-transform: uppercase;
    color: var(--darkGray);
    width: 100%;
    background-color: var(--white);
    border: none;
    cursor: pointer;
    display: block;
    font-size: 120%;
    padding: 0.5em;
}

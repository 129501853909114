.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
}

.choiceGrid {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.choiceGrid > div {
    display: flex;
    flex-direction: column;
    min-width: 20vw;
    border: 0.125em solid var(--lightGray);
    padding: 1em;
}

.inputBar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.inputBar > input {
    width: 50%;
}

.buttonBar {
    display: flex;
    gap: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
    align-items: center;
}

.buttonBar label {
    display: flex;
    align-items: center;
}

@media (max-width: 1200px) {
    .inputBar > input {
        width: 90%;
    }
}

.flash {
    outline: solid;
    align-content: center;
    text-align: left;
    margin: 1em 3em;
    padding: 1em 1em;
    border-radius: 10px;
    font-size: 1.25em;
}

.error {
    color: var(--white);
    background-color: var(--lightRed);
    outline-color: var(--red);
}

.warning {
    color: var(--white);
    background-color: var(--lightOrange);
    outline-color: var(--orange);
}

.info {
    background-color: var(--lightBlue);
    outline-color: var(--blue);
}

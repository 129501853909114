.wrapper {
    display: flex;
    justify-content: flex-start;
}

.wrapper > div {
    padding: 1em;
}

.detailTable {
    display: grid;
    grid-template-columns: auto auto;
}

.detailTable > *:nth-child(1),
.detailTable > *:nth-child(2) {
    border-top: 1px solid var(--lightGray);
}

.detailTable > *:nth-child(odd) {
    font-weight: bold;
}

.detailTable > *:nth-child(even) {
    border-right: 1px solid var(--lightGray);
}

.detailTable > * {
    margin: 0;
    padding: 0.25em;
    min-width: 5em;
    border-left: 1px solid var(--lightGray);
    border-bottom: 1px solid var(--lightGray);
}

.bulkTable {
    padding: 0 !important;
    display: grid;
    grid-template-columns: repeat(4, auto);
    border-right: 1px solid var(--lightGray);
    border-top: 1px solid var(--lightGray);
}

.bulkTableHeader {
    font-weight: bold;
}

.bulkTable p {
    border-left: 1px solid var(--lightGray);
    border-bottom: 1px solid var(--lightGray);
    margin: 0;
    padding: 0.25em;
}

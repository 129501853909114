.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.strategySettingBox {
    padding-top: 2em;
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5em;
}

.buttonBox {
    display: flex;
    justify-content: space-between;
    grid-gap: 0.5em;
    padding-bottom: 1em;
}

.wrapper > div {
    width: 80%;
}

.paramBox {
    margin-bottom: 3em;
    display: grid;
    grid-gap: 0.5em;
    grid-template-columns: repeat(4, 1fr) 3em;
    align-items: center;
    border: 0.125em solid var(--lightGray);
    padding: 1em;
}

.paramBox > p {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.info {
    color: var(--green);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 150%;
    cursor: help;
}

.bold {
    font-weight: bold;
}

.seperator {
    grid-column: 1 / -1;
    height: 0.25em;
    background-color: var(--lightGray);
}

.sliderRow {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    padding-bottom: 1em;
    align-items: center;
}

.slider {
    -webkit-appearance: none;
    width: 90%;
    height: 15px;
    border-radius: 5px;
    background: var(--lightGray);
    outline: none;
    -webkit-transition: 0.2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--green);
    border: none;
    cursor: pointer;
}

.slider:disabled,
::-webkit-slider-thumb,
.slider:disabled::-moz-range-thumb {
    background: var(--lightGray);
    cursor: not-allowed;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--green);
    cursor: pointer;
    border: none;
}

@media (max-width: 1400px) {
}

.button {
    font-size: 100%;
    background-color: var(--green);
    border: none;
    padding: 1em 2em;
    color: var(--white);
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;
    max-width: 20em;
    display: block;
    margin: 0;
    text-align: center;
}

.actionButton {
    font-size: 100%;
    background-color: var(--green);
    border: none;
    color: var(--white);
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;
    max-width: 20em;
    display: block;
    margin: 0;
}

.orangeButton {
    font-size: 100%;
    background-color: var(--orange);
    border: none;
    padding: 1em 2em;
    color: var(--white);
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;
    max-width: 20em;
    display: block;
    margin: 0;
    text-align: center;
}

.lightBlueButton {
    background-color: var(--blue);
    border: none;
    padding: 1em 2em;
    color: var(--white);
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;
    max-width: 20em;
    display: block;
    margin: 0;
}

.submitButton {
    background-color: var(--green);
    border: none;
    padding: 1em 2em;
    color: var(--white);
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;
    max-width: 20em;
    display: block;
    margin: 0;
}

.lightBlueButton:disabled,
.orangeButton:disabled,
.submitButton:disabled {
    background-color: var(--lightGray);
    cursor: auto;
}

.bigButton {
    font-size: 200%;
    padding: 0.5em 1em;
}

.dangerButton {
    background-color: var(--red);
}

.button:hover {
    transform: scale(1.02);
}

.button:active {
    transform: scale(0.98);
}

.button:disabled {
    background-color: var(--lightGray);
    transform: scale(1) !important;
    cursor: default;
}

.disabledLink {
    color: var(--lightGray);
    pointer-events: none;
}

.cleanButton {
    border: 1px solid var(--lightGray);
    padding: 0.5em 1em;
    font-weight: 400;
    color: var(--black);
    outline: none;
    cursor: pointer;
    background: none;
    font-size: 18px;
    border-radius: 5px;
}

.selectButton {
    border: 1px solid var(--darkGray);
    background-color: var(--white);
    padding: 1em 2em;
    color: var(--darkGray);
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;
    font-weight: 500;
}

.selectButton:hover {
    background-color: var(--orange);
    color: var(--white);
    border: 1px solid var(--orange);
}

input:checked + .selectButton {
    background-color: var(--orange);
    color: var(--white);
    border: 1px solid var(--orange);
}

input:disabled + .selectButton {
    cursor: default;
    background-color: var(--lightGray);
}

input:disabled + .selectButton:hover {
    background-color: var(--lightGray);
    color: var(--darkGray);
    border: 1px solid var(--darkGray);
}

.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 5em;
}

.navBox {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logo {
    height: 4em;
}

.appName {
    color: var(--green);
    font-weight: bold;
    font-size: 1.2em;
}

.navBox > * {
    margin-left: 1em;
    margin-right: 1em;
}

.userBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 1em;
}

.userBox * {
    color: var(--green);
}

.userBox > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1em;
    height: 100%;
}

.userBox p {
    font-weight: bold;
    margin: 0.5em;
}

.buttonBox {
    font-weight: normal;
    font-size: 175%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.navButton {
    cursor: pointer;
    transition: 0.3s;
}

.navButton:hover {
    transform: scale(1.05);
}

.userBox img {
    border-radius: 50%;
    height: 4em;
    filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.15));
}

.linkBox {
    background-color: var(--white);
    overflow: hidden;
}

.pageName {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    font-size: 110%;
    color: var(--green);
}

.dropdown {
    display: flex;
    flex-direction: row;
    gap: 4em;
    padding-left: 2em;
}

.dropdown ul {
    height: max-content;
    padding: 0 0 1em 0;
    margin: 0;
    list-style: none;
}

.dropdown li {
    font-size: 1em;
    font-weight: 400;
    padding-top: 0.5em;
}

.dropdown h1 {
    padding: 0;
    margin: 0;
    font-size: 1.1em;
}

.navContent {
    display: flex;
}

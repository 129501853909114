.buttonBar {
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
}

.buttonBar button {
    text-transform: uppercase;
    color: var(--darkGray);
    width: 100%;
    background-color: var(--white);
    border: none;
    cursor: pointer;
    display: block;
    font-size: 120%;
    padding: 0.5em;
}

.header {
    background-color: var(--lightGray);
    text-align: center;
    justify-content: center;
    padding: 1em;
    font-size: 125%;
    font-weight: bold;
}

.impactHeader {
    margin: 0;
    grid-column: 1 / span 2;
    padding: 1em 0 0.5em 0;
}

.spanTwoCell {
    grid-column: 1 / span 2;
}

.table p {
    align-items: center;
    display: flex;
}

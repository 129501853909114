.wrapper {
    max-width: calc(99vw - 4rem);
    overflow-y: auto;
}

.title {
    padding-left: 0.5em;
}

.table {
    display: grid;
    margin: 0 1em 1em 1em;
    overflow-x: auto;
    font-size: 80%;
}

.header {
    padding: 1em 0.3em;
    margin: 0;
    text-transform: capitalize;
    font-weight: 500;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--lightGray);
}

.header > svg {
    margin-left: 1em;
}

.cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.3em;
    border-left: 1px solid var(--lightGray);
    border-bottom: 1px solid var(--lightGray);
    margin: 0;
    text-transform: capitalize;
}

.boldCell {
    font-weight: bold;
}

.topCell {
    border-top: 1px solid var(--lightGray);
}

.fullWidthCell {
    overflow: hidden;
    padding: 0.3em;
    border-left: 1px solid var(--lightGray);
    border-bottom: 1px solid var(--lightGray);
    margin: 0;
    text-transform: capitalize;
    overflow: unset;
}

.ulCell {
    margin: 0;
    padding-left: 1em;
}

.cell:hover {
    text-overflow: unset;
    white-space: unset;
    overflow: unset;
}

.actionButton {
    color: var(--white);
    padding: 0.4em;
    margin: 0.125em 0;
    border-radius: 5px;
    font-size: 115%;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.negativeActionButton {
    background-color: var(--red);
}

.negativeActionButton:disabled {
    background-color: var(--darkGray);
}

.positiveActionButton {
    background-color: var(--green);
}

.actionCell {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--lightGray);
    border-right: 1px solid var(--lightGray);
    border-left: 1px solid var(--lightGray);
}

.lastCell {
    border-right: 1px solid var(--lightGray);
}

.dividerCell {
    border-right: 3px solid var(--lightGray);
}

.actionHeaderCell {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkCell {
    padding: 1em;
    margin: 0;
    border-left: 1px solid var(--lightGray);
    border-bottom: 1px solid var(--lightGray);
}

.linkCell:hover {
    color: var(--green);
}

.cellLoader {
    height: 100%;
    width: 100%;
}

.paginationLoader {
    height: 2em;
    margin: 0.25em;
}

.paginationBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    font-size: 120%;
    margin-bottom: 1em;
}

.paginationControls {
    margin: 0.25em;
    cursor: pointer;
}

.pagination {
    background: none;
    margin: 0.25em;
    padding: 0.25em;
    text-align: center;
    min-width: 1.5em;
    color: var(--black);
    border-radius: 5px;
    cursor: pointer;
}

.activePagination,
.pagination:hover {
    background-color: var(--green);
    color: var(--white);
}

.buttonBar {
    padding-top: 0.8em;
    margin: 0 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1em;
}

.centerButtonBar {
    justify-content: center;
    align-items: center;
}

.buttonBar h3 {
    margin: 0;
}

.areYouSureBox {
    background-color: var(--white);
    padding: 0.25em 1em;
    box-shadow: var(--shadow);
    font-size: 200%;
    border-radius: 15px;
}

.areYouSureBox div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.sectionTitle {
    margin: 1em;
}

.statusCell {
    text-transform: lowercase;
}

/*.editCell {*/
/*    width: 8em;*/
/*}*/

.editCell input {
    width: 90%;
}

.editCell:hover {
    background-color: var(--lightGray);
    cursor: text;
}

.label {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 500;
}

.stretchFilterBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
}

.stackedFilterBar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1em;
    gap: 1em;
}

.filterBar {
    display: flex;
    flex-wrap: wrap;
    gap: 3em;
}

.filterInnerSection {
    display: flex;
    flex-direction: column;
    max-width: 60em;
    gap: 0.3em;
}

.filterInnerSection > p {
    margin: 0.2em;
}

.filterInnerSectionStatic {
    font-weight: 500;
    color: var(--green);
}

.buttonSection {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 1em;
}

.confirmBox {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.text {
    padding: 0.4em;
    border-radius: 5px;
    border: var(--inputBorder);
    border-width: 1px;
    height: 25px;
    font-family: Roboto, sans-serif;
    font-size: 0.9em;
}

.select {
    padding: 0.5em;
    border-radius: 5px;
    border: var(--inputBorder);
    background-color: var(--white);
}

.hidden {
    display: none;
}

.textArea {
    padding: 0.4em;
    border-radius: 5px;
    border: var(--inputBorder);
    border-width: 1px;
    height: 25px;
    font-family: Roboto, sans-serif;
    font-size: 0.9em;
    min-height: 5em;
}

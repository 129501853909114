.form {
    display: flex;
    gap: 1em;
    align-items: center;
}

.dropWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.dropZone {
    display: flex;
    width: 80vw;
    height: 50vh;
    border: 0.5em dashed var(--lightGray);
    border-radius: 1em;
    justify-content: center;
    align-items: center;
}

.highlight {
    border: 0.5em dashed var(--green);
}

.uploadedList {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.fileBox {
    border: 0.125em solid var(--lightGray);
    margin: 1em;
    border-radius: 5px;
    display: flex;
    gap: 1em;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.5em;
}

.fileBox p {
    margin: 1em;
    font-size: 200%;
}

.buttonBar {
    display: flex;
    justify-content: space-between;
    margin: 1em;
    align-items: center;
}

.selectMany {
    min-width: 20em;
}

.buttonBar {
    display: flex;
    gap: 1em;
}

.selectField {
    min-width: 15em;
}

.formBar > * {
    margin-top: 0.5em;
}

.formBar {
    width: 80%;
}

.headerDates {
    display: flex;
    align-items: center;
    gap: 1em;
}

.list_250 {
    display: flex;
}

.list_250 span {
    color: var(--white);
    padding: 0.2em 0.5em;
    margin: 0.1em;
    border-radius: 5px;
}

.linkButton {
    margin-left: 1em;
    padding-bottom: 3em;
}

.linkButton * {
    margin-left: 1em;
}

.sectionHeading {
    margin-left: 0.8em;
    margin-bottom: 0;
    margin-top: 1.5em;
    color: var(--green);
}

.table {
    display: grid;
    margin: 0 1em 1em 1em;
}

.dependentBox {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.selectMany {
    min-width: max(200px, 20vw);
}

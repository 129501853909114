.wrapper {
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.level2selectList {
    padding: 1em;
    margin: 1em;
    border: 0.125em solid var(--lightGray);
}

.selectList {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
}

.item {
    gap: 1em;
    display: flex;
    padding: 1em;
    justify-content: space-evenly;
    align-items: center;
    border: 0.125em solid var(--lightGray);
}

.buttonBar {
    margin-top: 2em;
    display: flex;
    gap: 1em;
}

.settingBox {
    display: flex;
    gap: 1em;
    margin-top: 1em;
    justify-content: flex-start;
    align-items: center;
}

.titleCheck {
    display: flex;
    gap: 1em;
    align-items: center;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
}

.buttonBar {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
}

.listHeader {
    border-bottom: 1px solid var(--lightGray);
    overflow-y: hidden;
}

.listHeader p:nth-child(2) {
    text-align: center;
}

.sortList {
    width: 80%;
    border: 1px solid var(--lightGray);
    margin: 1em;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.rank {
    font-size: 200%;
    width: 100%;
    text-align: center;
    margin: 0.25em 0;
}

.listItem {
    display: grid;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    grid-template-columns: 3em 4em 1fr 1fr 1fr 1fr 1fr;
}

.dragHandle {
    width: 3em;
    cursor: grab;
}

.contentLoader {
    height: 5em;
    width: 100%;
}

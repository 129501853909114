.wrapper {
    height: 100vh;
    width: 100vw;
    background: var(--gradient);
    display: grid;
    align-items: center;
}

.loginBoxWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: 1 / 1;
}

.loginBox {
    background-color: var(--white);
    height: 50vh;
    min-width: 40em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.shapeSVG {
    grid-area: 1 / 1;
    height: 100%;
    object-fit: cover;
}

.loginButton {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--green);
    color: var(--white);
    padding: 1em;
    font-size: 20pt;
}

.loginBox h1 {
    font-size: 230%;
}

.loginButton > * {
    padding: 0.25em;
}

.label {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.badge {
    color: var(--white);
    font-size: 14px;
    background-color: var(--green);
    text-transform: capitalize;
    padding: 0.2em 0.5em;
    margin: 0.1em;
    border-radius: 5px;
}

.badge_hageda {
    color: var(--white);
    font-size: 14px;
    background-color: var(--red);
    text-transform: capitalize;
    padding: 0.2em 0.5em;
    margin: 0.1em;
    border-radius: 5px;
}

.form {
    display: grid;
    grid-template-columns: 30% 70%;
    gap: 1em;
}

.metricBox {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 0.5em;
    margin-left: 1em;
    margin-right: 1em;
}

.metricBox p {
    margin: 0;
    display: flex;
    align-items: center;
}

.metricBox p:nth-child(odd) {
    font-weight: bold;
}

.metricBox p:nth-child(even) {
    text-align: end;
}

.strike {
    text-decoration: double line-through 0.125em var(--red);
}

.strike span {
    padding: 0;
}

.strike::before,
.strike::after {
    content: "--";
}

.banner {
    color: var(--white);
    background: var(--gradient);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em;
    transition: 0.3s;
}

h1 {
    text-transform: capitalize;
}

.local {
    background: var(--local-gradient) !important;
}

.dev {
    background: var(--dev-gradient) !important;
}

.test {
    background: var(--test-gradient) !important;
}

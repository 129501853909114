.label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.badge {
    color: var(--white);
    text-transform: capitalize;
    padding: 0.25em 0.5em;
    border-radius: 5px;
}

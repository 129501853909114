.toggle {
    display: flex;
    height: 2em;
    width: 4em;
    border-radius: 1em;
    align-items: center;
    transition: 0.1s;
}

.input {
    display: none;
}

.handle {
    height: 1.5em;
    width: 1.5em;
    margin-right: 0.25em;
    margin-left: 0.25em;
    border-radius: 100%;
    background-color: var(--white);
}

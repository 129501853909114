.container {
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.container a:hover {
    color: var(--green);
}

.buttonSection {
    display: flex;
    gap: 1em;
}

.reportBox {
    padding: 0.5em;
    border-radius: 5px;
}

.detailTable {
    display: grid;
    grid-template-columns: 1fr 4fr;
    margin-bottom: 1em;
}

.container summary {
    font-size: 120%;
}

.isCorrection {
    display: flex;
    gap: 1em;
    align-items: center;
}

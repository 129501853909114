.formGrid {
    display: grid;
    grid-template-columns: 3fr 7fr;
    gap: 0.5em;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.pricingParamsFormGrid {
    display: grid;
    grid-template-columns: 3fr 3fr 0.25fr;
    gap: 0.5em;
    grid-auto-rows: min-content;
    justify-content: flex-start;
    align-items: flex-start;
    width: 45vw;
    padding-right: 1em;
    flex-grow: 0;
}

.inputRow {
    margin: 1em;
    display: flex;
    gap: 1em;
    align-items: center;
}

.wrapper {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    padding-left: 7.5%;
    width: 80%;
}

.footerSpacer {
    padding-bottom: 5em;
}

.pricingParamsWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.right {
    grid-column: 2;
}

.loading {
    width: 100%;
}

.center {
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
}

.optimizationHeader {
    display: grid;
    grid-template-columns: 0.75fr 0.25fr 1.75fr 0.25fr 0.15fr;
    justify-content: center;
    align-items: center;
    min-width: 0;
}

.optimizationFormGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 1em;
    justify-content: center;
    align-items: center;
}

.modelConfigurationFormGrid {
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr 1fr;
    gap: 1em;
    padding: 2em;
}

.inputBoxSpanDouble {
    grid-column: span 3;
}

/* Slider */
.sliderHeader {
    display: flex;
    flex: 1 1 0;
    justify-content: space-between;
}

.slidecontainer {
    width: 100%; /* Width of the outside container */
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider {
    -webkit-appearance: none;
    width: 90%;
    height: 15px;
    border-radius: 5px;
    background: var(--lightGray);
    outline: none;
    -webkit-transition: 0.2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--green);
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--green);
    cursor: pointer;
}

.rowWrapper {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    gap: 1em;
    padding: 1em;
}

.legend h1 {
    grid-column: 1 / -1;
}

.legend p {
    margin: 0;
}

.legend {
    align-self: flex-start;
    border: 0.25em solid var(--lightGray);
    border-radius: 15px;
    padding: 1em;
    grid-gap: 1em;
    display: grid;
    grid-template-columns: auto auto;
}

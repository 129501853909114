.sideNavWrapper {
    display: flex;
    flex-direction: row;
}

.sideNav {
    display: flex;
    flex-direction: column;
    height: 100vh;
    z-index: 100;
    justify-content: space-between;
}

.mainWrapper {
    border-left: 0.125em solid var(--green);
    flex-grow: 1;
}

.mainWrapperLocked {
    border-left: 0.25em solid var(--red);
}

.iconButton {
    border: none;
    background: none;
    font-size: 175%;
    color: var(--darkGray);
    display: flex;
    justify-content: flex-start;
}

.navButtons {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    grid-gap: 2em;
}

.navHeader {
    display: flex;
    align-items: center;
    padding: 0 1em;
}

.navHeader button,
.navHeader h1,
.navBackHeader button {
    cursor: pointer;
}

.navHeader h1 {
    margin: 0;
    font-size: 150%;
    margin-left: 0.5em;
    white-space: nowrap;
}

.navBackHeader {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 1em;
}

.navList {
    height: max-content;
    padding: 0;
    margin: 0;
    list-style: none;
}

.navList li a {
    display: block;
}

.navList li:first-child {
    margin-top: 1em;
}

.navList a {
    padding: 0.5em 0.5em 0.5em 2em;
    cursor: pointer;
}

.navList a:hover {
    background-color: var(--green);
    color: var(--white);
}

.green {
    color: var(--green);
}

.locked {
    color: var(--red) !important;
}

.logo {
    margin-top: 0.5em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-content: center;
}

.logoExpanded {
    justify-content: flex-start;
    padding-left: 1em;
}

.logo img {
    height: 3em;
}

.logoExpanded img {
    height: 5em;
}

.userBox {
    padding-top: 4em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 1em;
}

.userBox svg {
    color: var(--green);
}

.userBox > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1em;
    height: 100%;
}

.userBox p {
    font-weight: bold;
    margin: 0.5em;
}

.buttonBox {
    font-weight: normal;
    font-size: 175%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.userBox img {
    border-radius: 50%;
    height: 4em;
    filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.15));
}

.verticalUserBox a,
.verticalUserBox svg {
    color: var(--green);
    font-size: 125%;
}

.verticalUserBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    margin-bottom: 1em;
}

.verticalUserBox img {
    border-radius: 50%;
    height: 3.5em;
    filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.15));
}

.verticalUserBoxPlaceholder {
    max-width: 5em;
}

.errorWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.errorWrapper details {
    padding: 3em;
    white-space: pre-wrap;
}

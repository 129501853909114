.modalWrapper {
    background-color: var(--blocker);
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
}

.modal {
    min-width: 20vw;
    overflow-y: auto;
    background-color: var(--white);
    padding-left: 1em;
    padding-right: 1em;
    max-width: calc(100vw - 2em);
    height: 100vh;
    overflow-x: hidden;
}

.calculationSettings {
    background-color: var(--white);
    padding-left: 5em;
    padding-right: 3.5em;
    width: 35%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.searchArea {
    display: flex;
    flex-direction: row;
    padding: 1em;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1em;
    width: 100%;
}

.filterButton {
    font-size: 200%;
}

.filterBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 5em;
    border-left: 0.125em solid var(--lightGray);
}

.searchWrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.searchBox {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.searchBox > input {
    border: 1px solid var(--lightGray);
    height: 3em;
    font-size: 1em;
}

.filterButton {
    font-size: 200%;
}

.filterType {
    font-size: 150%;
    margin: 0 0.25em 0 0;
    font-weight: bold;
}

.filterBox {
    overflow: hidden;
}

.topBar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.25em;
}

.filterBar {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
}

.trash {
    background-color: var(--red);
    color: var(--white);
    padding: 0.25em 0.5em;
    margin: 0.125em 0.25em;
    border-radius: 5px;
    font-size: 115%;
    border: none;
}

.container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.filterDisplay {
    padding: 0.2em 0;
}

.filterAndOr {
    display: inline;
    font-weight: bold;
}

.suggestion {
    cursor: pointer;
    font-weight: bold;
}

.searchArea {
    display: flex;
    flex-direction: row;
    padding: 1em;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1em;
    width: 100%;
}

.searchBox > input {
    border: 1px solid var(--lightGray);
    height: 3em;
    font-size: 1em;
}

.searchWrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.searchBox {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.suggestion {
    cursor: pointer;
    font-weight: bold;
}

.filterButton {
    font-size: 200%;
}

.filterBox {
    overflow: hidden;
}

.filterRowBox {
    margin-left: 1em;
    display: grid;
    grid-gap: 0.5em;
    grid-template-columns: 3fr 1fr 2fr 3em;
}

.addButton {
    grid-column: span 4;
}

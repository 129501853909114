.expander {
    transition: 0.3s;
    grid-column: 1 / -1;
    min-height: 5em;
    overflow-y: hidden;
}

.expanderBody {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 5em;
    padding-bottom: 1em;
}

.expander h1,
.expander h2 {
    margin: 0;
    padding: 0.25em;
}

.bulkTable {
    display: grid;
    grid-template-columns: repeat(4, auto);
    border-right: 1px solid var(--lightGray);
    border-top: 1px solid var(--lightGray);
}

.competitors {
    margin-top: 3em;
}

.bulkTableHeader {
    font-weight: bold;
    text-align: left !important;
}

.bulkTable p {
    border-left: 1px solid var(--lightGray);
    border-bottom: 1px solid var(--lightGray);
    margin: 0;
    padding: 0.25em;
    text-align: right;
}

.smallDetailTable {
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 1em;
}

.detailTable {
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin-bottom: 1em;
}

.smallDetailTable > *:nth-child(1),
.smallDetailTable > *:nth-child(2) {
    border-top: 1px solid var(--lightGray);
}

.detailTable > *:nth-child(1),
.detailTable > *:nth-child(2),
.detailTable > *:nth-child(3),
.detailTable > *:nth-child(4) {
    border-top: 1px solid var(--lightGray);
}

.detailTable > *:nth-child(4n + 1) {
    font-weight: bold;
}

.detailTable > *:nth-child(4n) {
    border-right: 1px solid var(--lightGray);
}

.smallDetailTable > *:nth-child(odd) {
    font-weight: bold;
}

.smallDetailTable > *:nth-child(even) {
    border-right: 1px solid var(--lightGray);
}

.smallDetailTable > *,
.detailTable > * {
    margin: 0;
    padding: 0.25em;
    min-width: 5em;
    border-left: 1px solid var(--lightGray);
    border-bottom: 1px solid var(--lightGray);
}

.mainBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 1em;
    align-self: stretch;
}

.mainBox div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}

.mainBox button {
    justify-self: flex-end;
}

.mainBox a {
    margin-top: 3em;
}

.competitorBox {
    grid-gap: 1em;
    display: grid;
    grid-template-rows: auto auto;
    grid-auto-flow: column dense;
}
